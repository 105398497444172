import React from "react";
import { XCircleIcon, CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import {classNames} from "../helpers";

const icons = {
  success: <CheckCircleIcon className="w-6 h-6 white" />,
  error: <XCircleIcon className="w-6 h-6 text-red-600" />,
  warning: <ExclamationTriangleIcon className="w-6 h-6 text-yellow-600" />,
  info: <InformationCircleIcon className="w-6 h-6 text-blue-600" />,
};

const colors = {
  success: "bg-brand-green border-brand-green text-white",
  error: "bg-red-100 border-red-500 text-red-700",
  warning: "bg-yellow-100 border-yellow-500 text-yellow-700",
  info: "bg-blue-100 border-blue-500 text-blue-700",
};

interface AlertProps {
  message?: string;
  type?: "success" | "error" | "warning" | "info";
  isVisible: boolean;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ message = "", type = "info", isVisible, onClose }) => {
  return (
    <motion.div
      initial={{ y: 200 }}
      animate={{ y: isVisible ? 0 : 200 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className={`fixed bottom-4 transform w-full z-50 flex items-center justify-center transition-all duration-300 ${
        "block"
      }`}
    ><div className={classNames("w-96 p-4 border-l-4 rounded-lg shadow-lg flex items-center justify-between", colors[type])}>
      <div className="flex items-center">
        {icons[type]}
        <span className="ml-3">{message}</span>
      </div>
      <button onClick={onClose}>
        <XMarkIcon className="w-5 h-5 text-white hover:text-gray-900" />
      </button>
    </div>
    </motion.div>
  );
};

export default Alert;
