import * as React from "react"
import {ReactComponent as HeroCloud} from '../assets/images/about_sub_hero.svg'
import {Parallax} from "react-scroll-parallax";
import {classNames} from "../helpers";

const SvgComponent = ({className}: { className?: string }) => (
  <div className={classNames("relative", className || "")} style={{width: 960, height:960}}>
    <div className="absolute left-0">
      <div className="transition group relative rounded-full" style={{width: 960, height:960}}>
        <Parallax speed={-10} className="absolute z-10 top-28 -left-14"><HeroCloud className="w-72 text-brand-yellow" /></Parallax>
        <Parallax speed={-5} className="absolute z-10 bottom-20 left-6"><HeroCloud className="w-48 text-brand-yellow" /></Parallax>
        <Parallax speed={10} className="absolute z-10  bottom-52 right-48"><HeroCloud className="w-60 text-brand-yellow" /></Parallax>
        <div className="absolute inset w-full h-full flex p-10">
          <div className="relative w-full h-full bg-brand-blue-lightest rounded-full" />
        </div>
        <svg className="z-10 relative w-" height={960} id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="-30 -60 600.04 575.88">
          <g className="overflow-visible">
            <path className="about-hero-svg hero-space translate-y-10"
                  d="M87.62,162.38c.56.25,1.15.36,1.74.36-.86,2.32-1.61,4.65-2.23,6.96-4.44,3.72-9.96,8.6-14.71,12.87-.3-1.22-.53-2.54-.68-3.95-.72-6.6.29-14.41,2.84-22.3.33.31.71.58,1.15.78l11.89,5.29Z"/>
            <path className="about-hero-svg hero-space translate-y-7"
                  d="M88.27,152.61c.09.26.16.52.25.78l-9.29-4.13c-.58-.26-1.18-.36-1.78-.36,3.28-7.3,7.58-13.56,12.33-17.89,3.71-3.38,7.33-5.25,10.55-5.54-4,4.34-7.63,8.74-10.73,13.01-1.97.27-3.54.57-4.42.86-4.22,1.38-2.21,9.94,3.1,13.27Z"/>
            <path className="about-hero-svg hero-glasses translate-y-9"
                  d="M88.51,153.38c.87,2.78,1.24,5.58,1.36,8-.13.33-.27.66-.39,1-.05.12-.08.24-.13.36-.58,0-1.17-.11-1.74-.36l-11.89-5.29c-.44-.2-.81-.47-1.15-.78.37-1.13.76-2.26,1.19-3.39.52-1.37,1.08-2.71,1.67-4.03.6,0,1.2.1,1.78.36l9.29,4.13Z"/>
            <path className="about-hero-svg hero-glasses translate-y-8"
                  d="M89.77,131c-4.76,4.33-9.05,10.59-12.33,17.89-.59,1.31-1.15,2.65-1.67,4.03-.43,1.13-.83,2.26-1.19,3.39-2.55,7.89-3.56,15.7-2.84,22.3.15,1.42.38,2.73.68,3.95-1.16,1.04-2.26,2.04-3.3,2.98-.64-1.95-1.1-4.11-1.36-6.5-.88-8.16.63-17.94,4.27-27.54,3.64-9.6,8.98-17.93,15.05-23.46,5.8-5.28,11.7-7.48,16.82-6.33-1.22,1.24-2.42,2.5-3.58,3.75-3.22.29-6.84,2.16-10.55,5.54Z"/>
            <path className="about-hero-svg hero-none"
                  d="M134.59,371.37c.86,6.58,2.07,12.37,3.63,16.57-2.24-5.23-3.13-10.88-3.63-16.57Z"/>
            <path className="about-hero-svg hero-neck translate-x-10 translate-y-24"
                  d="M177.4,344.05c8.73-26.06,50.1-49.88,50.1-49.88,0,0,0,0,0,0,21.17-3.85,41.22-8.58,54.73-14.16-.08.18-.16.35-.26.55-2.19,4.04-7.38,7.25-10.44,10.71-3.62,4.09-6.88,8.52-9.55,13.29-6.17,11.02-9.16,24.11-6.66,36.48.26,1.3.58,2.63.31,3.92,0,0-43.15,10.86-77.16,22.26-2.85-6.54-4.03-14.38-1.09-23.17Z"/>
            <path className="about-hero-svg hero-neck translate-x-4 translate-y-24"
                  d="M138.22,349.94c1.75,1.97,5.04,1.86,7.22.37,2.18-1.48,3.44-3.97,4.35-6.44,2.02-5.45,2.8-11.27,3.25-17.06.44-5.67.56-11.5-1.23-16.9-.57-1.72-1.37-3.39-2.36-4.92,19.12-2,49.57-5.61,78.06-10.8,0,0,0,0,0,0,0,0-41.38,23.81-50.1,49.88-2.94,8.79-1.76,16.63,1.09,23.17-23.08,7.74-41.96,15.72-40.28,20.72-1.55-4.2-2.77-9.99-3.63-16.57-1.66-12.73-1.99-28.43-.86-41.41,1.35,5.16,1.97,10.53,2.9,15.8.26,1.49.59,3.04,1.59,4.17Z"/>
            <path className="about-hero-svg hero-neck translate-y-24"
                  d="M141.66,305.77c2.31-.23,4.92-.49,7.78-.79,1,1.53,1.79,3.2,2.36,4.92,1.79,5.4,1.67,11.23,1.23,16.9-.45,5.79-1.23,11.62-3.25,17.06-.92,2.47-2.18,4.96-4.35,6.44-2.18,1.48-5.47,1.6-7.22-.37-1-1.13-1.33-2.69-1.59-4.17-.93-5.27-1.55-10.65-2.9-15.8-.48-1.84-1.05-3.65-1.77-5.41-1.67-4.1-4.24-8-7.72-10.78-1.7-1.36-3.61-2.48-5.69-3.16-.81-.27-2.32-.28-3.68-.42,11.88-.72,20.95-3.51,25.15-4.06.33-.04.9-.16,1.64-.35Z"/>
            <path className="about-hero-svg hero-cheek translate-y-16 translate-x-10"
                  d="M149.45,304.98c-2.86.3-5.47.56-7.78.79,2.62-.66,7.6-2.29,13.63-5.36,2.22-1.09,4.38-2.3,6.48-3.66.2-.13.4-.24.6-.37,2.18-1.39,4.3-3.04,6.35-4.9.4-.35.79-.73,1.18-1.1.12-.11.24-.22.36-.34,2.92-2.78,5.65-5.95,8.17-9.59,2.74-3.81,5.25-7.93,7.45-12.21,10.02,6.8,27.15,4.16,41.13-7.11,16.03-12.92,21.86-32.29,13.02-43.27-.05-.07-.11-.13-.17-.19,8.31,1.42,16.59,10.94,22.23,16.45,7.71,7.53,14.69,16.01,19.64,25.64,4.72,9.17-2.9,19.58,2.03,19.59-.5.22-1.01.43-1.53.64-13.51,5.58-33.57,10.31-54.73,14.16-28.48,5.19-58.93,8.8-78.06,10.8Z"/>
            <path className="about-hero-svg hero-none"
                  d="M59.74,223.82s-.03-.01-.05-.02c.41-1.46.82-2.92,1.22-4.37.02,0,.03.02.05.02-.41,1.45-.81,2.9-1.22,4.37Z"/>
            <path className="about-hero-svg hero-high-cheek translate-x-7 translate-y-7"
                  d="M114.37,139.33c-.65-.38-1.5-.68-2.52-.92,2.39-1.4,4.69-2.13,6.79-2.13,1.03,0,2.01.17,2.93.52,3.99,1.51,6.61,6.24,7.38,13.3.11.98.17,1.98.2,3-4.29-5.03-11.4-11.81-14.77-13.77Z"/>
            <path className="about-hero-svg hero-eyes translate-x-7 translate-y-10"
                  d="M108.56,173.02c.14-1.7-.18-3.28-.82-4.49,1.6.3,3.16.68,4.69,1.1,3.11,1.16,5.56,2.81,5.56,2.81,0,0,.47.37.9.72s-4.72,3.61-7.54,3.91c-2.81.3-3.75,1.31-2.77,1.11.45-.09.83-.09,1.29-.07-2.44.5-3.83.8-5.52.89,2.12-.28,3.93-2.78,4.2-5.98Z"/>
            <path className="about-hero-svg hero-forehead translate-x-8"
                  d="M155.37,96.82c2.49,1.12,15.1,9.02,31.57,20.2-4.48,3.65-8.88,7.75-10.77,11.21-3.64,1.91-7.24,7.83-9.41,10.42-9.44,11.25-17.92,23.32-25.31,36.02-1.75-6.95-7.03-13.58-8.53-15.38.31-3.37.32-6.6,0-9.62-.94-8.64-4.47-14.54-9.94-16.61-5.06-1.92-11.05-.27-17.01,4.62-5.41-.27-11.92.18-16.38.79,3.1-4.27,6.73-8.66,10.73-13.01,1.16-1.26,2.36-2.51,3.58-3.75,11.13-11.35,24.62-21.96,37.9-28.38,1.01-.49,2-.94,2.97-1.38.3,1.11.66,1.97,1.1,2.49,1.04,1.35,3.48-.32,9.5,2.39Z"/>
            <path className="about-hero-svg hero-none "
                  d="M103.84,179.02c-.12,0-.25,0-.37,0,.05,0,.09-.01.14-.02.03,0,.06.01.1.02.04,0,.09,0,.13,0Z"/>
            <path className="about-hero-svg hero-cheek translate-x-10 translate-y-8"
                  d="M142.92,178.6l-.57-.25c-.22-.99-.52-2.2-.9-3.68,7.38-12.7,15.86-24.76,25.31-36.02,2.17-2.58,5.77-8.5,9.41-10.42-.62,1.13-.97,2.19-.97,3.14,0,6.64,33.84,43.13,35.17,53.09,1.11,8.31-14.42,24.93-19.2,33.68-8.89-9.76-29.83-24.38-45.94-34.28.68-2.06-.29-4.36-2.31-5.26Z"/>
            <path className="about-hero-svg hero-cheek translate-y-16"
                  d="M72.38,258.64c1.32-.61,1.62-2.69,1.53-4.13-.64-9.61-3.57-26.78-14.16-30.69.41-1.46.82-2.92,1.22-4.37,1.88.91,4.49,1.94,7.39,2.39,5.75.88,17.03-4.65,12.39-14.16-1.98-4.05,2.12-7.29,5.44-12.56,1.59,5.8,4.68,9.78,8.96,11.41,1.38.52,2.83.78,4.33.78,4.44,0,9.31-2.27,14.12-6.64,5.27-4.8,10-11.72,13.53-19.76.14.08.27.17.41.24l11.89,5.29c2.16.96,4.71-.02,5.66-2.18.06-.13.09-.27.13-.41,16.1,9.9,37.04,24.52,45.94,34.28-.95,1.73-1.48,3.16-1.37,4.15.05.43.27.79.62,1.09-.2.15-.4.3-.6.46-2.14,1.73-7.8,1.35-9.56,3.83-.99,1.39.35,8.75-.37,10.2-2.5,4.98-3,9.13-1.98,12.57-.36,5.36.92,10.32,4.06,14.23,1.13,1.4,2.44,2.59,3.91,3.59-2.21,4.27-4.71,8.4-7.45,12.21-2.52,3.64-5.25,6.82-8.17,9.59-.12.12-.24.22-.36.34-.39.37-.78.75-1.18,1.1-2.05,1.86-4.17,3.51-6.35,4.9-.2.13-.4.24-.6.37-2.1,1.36-4.26,2.57-6.48,3.66-6.03,3.07-11.01,4.7-13.63,5.36-.74.19-1.3.3-1.64.35-4.21.55-13.27,3.34-25.15,4.06-1.98.12-4.04.18-6.17.16-7.65.58-15.36,1.04-22.92,1.8-34.95,3.54-36.72-12.39-40.26-20.35-1.33-2.99.35-13.32,3.45-26.76,8.62,2.78,15.08-.82,23.41-6.4Z"/>
            <path className="about-hero-svg hero-high-cheek translate-y-9"
                  d="M88.27,152.61c1.64,1.03,3.28,1.33,5.07,1.24-1.27,2.39-2.43,4.91-3.46,7.53-.12-2.42-.49-5.21-1.36-8-.08-.26-.16-.52-.25-.78Z"/>
            <path className="about-hero-svg hero-high-cheek translate-x-6 translate-y-9"
                  d="M117.29,170.06c-1.91-1.11-9.15-3.55-13.67-3.75-2.48-.11-4.68-1.09-6.23-1.83-.46-.3-.92-.53-1.38-.63-.5-.2-.82-.26-.92-.05-.37.06-1.2.58-2.36,1.43.17-.48.32-.96.5-1.43,1.48-3.9,3.25-7.57,5.23-10.89,2.89-.74,6.26-1.69,10.46-2.06,7.27-.64,15.47,4.53,20.11,6.92-.35,4.96-1.45,10.27-3.26,15.61-.44-.13-.79-.21-1.04-.21,0-.69-5.53-1.99-7.44-3.09Z"/>
            <path className="about-hero-svg hero-none " d="M112.43,169.63c-1.53-.42-3.09-.8-4.69-1.1,1.59.1,3.21.54,4.69,1.1Z"/>
            <path className="about-hero-svg hero-high-cheek translate-x-7 translate-y-10"
                  d="M131.03,173.31c-.31-.14-.63-.22-.95-.28,1.46-4.67,2.43-9.31,2.84-13.74,1.51,1.79,6.78,8.43,8.53,15.38.37,1.48.67,2.69.9,3.68l-11.32-5.03Z"/>
            <path className="about-hero-svg hero-high-cheek translate-y-12"
                  d="M103.47,179.02c.12,0,.25,0,.37,0,.17,0,.34,0,.51-.03,1.7-.09,3.08-.4,5.52-.89.54.03,1.21.08,2.28.07,5.23-.07,12.56-4.32,12.56-5.01.25,0,.6.07,1.04.21-.27.81-.55,1.62-.85,2.43-3.42,9.02-8.39,16.81-14,21.92-5.25,4.78-10.35,6.59-14.34,5.08h0c-3.89-1.47-6.48-6.01-7.31-12.79,2.71-4.71,4.53-8.31,5.78-9.96.78-1.03,5.9-.67,8.44-1.01Z"/>
            <path className="about-hero-svg hero-ears translate-x-10 translate-y-14"
                  d="M183.48,257.72c1.18.83,2.49,1.39,3.88,1.71,1.82,1.93,3.86,4.12,5.29,5.63,1.47,1.55,3.09-2.48,4.1-6.6,4.53-1.79,9.05-5.34,12.57-10.3,6.42-9.07,7.3-19.77,2.55-25.55,9.32-1.72,18.43-4.04,20.2-4.39,2.17-.43,4.38-.87,6.59-.71.4.03.8.1,1.21.17.05.07.11.13.17.19,8.84,10.98,3.02,30.35-13.02,43.27-13.99,11.27-31.12,13.9-41.13,7.11-1.47-.99-2.78-2.19-3.91-3.59-3.14-3.9-4.43-8.87-4.06-14.23.86,2.91,2.8,5.32,5.57,7.28Z"/>
            <path className="about-hero-svg hero-ears translate-x-8 translate-y-14"
                  d="M211.87,222.61c4.75,5.78,3.87,16.48-2.55,25.55-3.51,4.96-8.04,8.51-12.57,10.3.9-3.7,1.3-7.48.65-7.4-1.39.18-15.18,3.22-13.91,4.37.62.56,2.15,2.16,3.87,4-1.39-.33-2.71-.88-3.88-1.71-2.77-1.97-4.71-4.37-5.57-7.28-1.02-3.44-.52-7.59,1.98-12.57.73-1.45-.61-8.81.37-10.2,1.76-2.48,7.42-2.1,9.56-3.83.2-.16.4-.3.6-.46,2.5,2.09,12.07.97,21.45-.76Z"/>
            <path className="about-hero-svg hero-forehead translate-x-16 translate-y-6"
                  d="M281.74,259.78c-4.95-9.63-11.93-18.11-19.64-25.64-5.64-5.51-13.92-15.03-22.23-16.45-.4-.07-.8-.14-1.21-.17-2.21-.16-4.41.27-6.59.71-1.76.35-10.88,2.67-20.2,4.39-9.38,1.73-18.96,2.85-21.45.76-.35-.3-.58-.65-.62-1.09-.11-.99.42-2.42,1.37-4.15,4.78-8.74,20.31-25.37,19.2-33.68-1.33-9.95-35.17-46.45-35.17-53.09,0-.95.36-2.01.97-3.14,1.89-3.46,6.29-7.56,10.77-11.21,23.44,15.92,54.7,38.51,75.71,57.71,18.67,17.06,45.21,39.07,51.54,63.56,1.19,4.61,3.37,10.55,8.12,10.25.2-.01.4-.04.6-.08.78.29,1.55.13,2.31-.56-1.66,2.03-3.65,4.3-6.02,6.9-12.97,14.21-28.12,24.57-35.42,24.57,0,0,0,0,0,0-4.93,0,2.69-10.42-2.03-19.59Z"/>
            <path className="about-hero-svg hero-none "
                  d="M332.22,164.12s0,.02,0,.04c-.28-.22-.57-.45-.86-.68.11.08.23.18.32.24.36.27.55.4.55.4Z"/>
            <path className="about-hero-svg hero-none " d="M151.79,36.74s.07,0,.1,0c-.19.2-.38.41-.55.64.12-.3.27-.52.45-.63Z"/>
            <path className="about-hero-svg hero-none"
                  d="M279.44,114.58c-1.86-1.97-3.73-3.99-5.59-6.02.55.59,1.84,1.97,4.87,5.22.19.2.43.47.72.79Z"/>
            <path className="about-hero-svg hero-crown translate-x-24"
                  d="M356.98,117.37s-9.38,7.41-37.79,4.27c-28.19-3.12-45.48-13.36-45.74-13.51-16.27-17.77-32.22-37.46-43.34-56.12-9.28-15.58-29.21,61.93-44.05,51.51-3.72-2.61,4.86-52.81-2.81-59.73-7.67-6.92-19.57-6.87-23.2-8.08-.3-.1-.61-.19-.92-.28-.3-4.72-.07-10.84,2.57-12.67,2.88-2,8.49,1.98,11.92-.45,1.62-1.14,3.22-2.82,5.18-2.57,3.98.5,2.82,6.5,3.78,10.4,1.04,4.24,6.57,6.66,10.39,4.54,1.07-2.81-.1-6.82,3.21-8.42,3.31-1.6,6.3,2.94,9.46,4.41,1.71-6.27,1.43-15.14,7.81-13.88,2.32,3.4-.66,10.84,2.07,11.91,2.74,1.07,7.22-1.07,5.57,1.93s-2.23,8.64.64,11.5c5.07,5.07,18.24,1.51,20.42,2.9,2.18,1.4,1.39-7.67,4.25-7.38,2.87.3,5.32,3.88,8.48,3.42,2.84-.42,7.26-1.02,7.18,1.85-2.82,3.25-5.76,6.76-6.3,11.03-.53,4.27,2.61,9.25,6.89,8.91,4.29-.34-.77-2.28.44-4.94s5.46-.12,6.67-2.51c1.2-2.39.94-5.24.5-7.88,5.42.51,9.53,4.87,13.48,8.61,3.95,3.74,9.21,7.33,14.45,5.83,2.99-.86,5.73-3.35,8.78-2.71,2.62.55,5.11,3.34,7.45,2.04,2.12-1.17,1.52-4.28,1.4-6.71-.12-2.42,4.46-10.6,4.15-3.7s-1.1,7.91-.22,11.76,7.83,3.68,7.32,8.53c-.5,4.85-3.52,7.21-3.07,11.07.45,3.86,6,3.98,7.92,6.49,1.92,2.5-2.14,6.57.69,6.86,2.83.29,5.41-1.95,8.11-2.92,1.04-.37,2.26-.73,3.19-.14.89.57,1.1,1.74,1.54,2.69,1.37,2.97,5.34,3.95,8.48,3.02,3.14-.93,4.83-6.49,7.99-5.54,3.16.95,1.39,6.36-.65,8.94-2.03,2.57-4.91,4.47-6.55,7.31s-2.38,8.02,1.89,8.38c1.45.12,2.21-.4,2.71-1.17,0,0,0,.02-.01.03-.63,1.39-2.36,1.16-2.36,1.16Z"/>
            <path className="about-hero-svg hero-none" d="M361.97,111.88s0,0-.73.37c.2-.17.44-.3.73-.37Z"/>
            <path className="about-hero-svg hero-none"
                  d="M364.94,132.79c-.42-.95-.91-2.99-1.2-6.44,0,0,0-.02,0-.03.29,2.43.65,4.69,1.2,6.48,0,0,0,0,0,0,0,0,0,0,0,0Z"/>
            <path className="about-hero-svg hero-none "
                  d="M322.91,248.46c1.33-.24,2.53-.98,3.66-1.79-.47.43-.92.85-1.35,1.24,0,0,0,0,0,0-.76.69-1.52.84-2.31.56Z"/>
            <path className="about-hero-svg hero-brows translate-y-8"
                  d="M93.33,153.85c-1.78.09-3.42-.21-5.07-1.24-5.31-3.33-7.31-11.9-3.1-13.27.88-.29,2.45-.59,4.42-.86,4.45-.61,10.97-1.06,16.38-.79-.48.39-.96.8-1.44,1.24-4.18,3.81-8.02,8.96-11.2,14.93Z"/>
            <path className="about-hero-svg hero-brows translate-x-6 translate-y-8"
                  d="M108.91,150.84c-4.2.37-7.56,1.32-10.46,2.06,2.61-4.36,5.58-8.12,8.77-11.02,1.56-1.42,3.11-2.58,4.62-3.46,1.02.24,1.87.55,2.52.92,3.37,1.97,10.48,8.74,14.77,13.77.05,1.5,0,3.06-.12,4.65-4.63-2.39-12.83-7.56-20.11-6.92Z"/>
            <path className="about-hero-svg hero-face translate-y-18"
                  d="M41.31,242.31c.62-.64,1.22-1.15,1.81-1.55,2.24,3.83,7.7,3.85,12.45,4.51,1.29.18,2.53.41,3.64.78,3.44,1.14,6.3,3.57,8.18,6.64.99,1.62,1.53,5.74,3.76,6.08.49.08.9.01,1.24-.15-8.33,5.59-14.79,9.18-23.41,6.4-1.4-.45-2.86-1.06-4.39-1.87-7.71-4.07-9.42-14.52-3.27-20.86Z"/>
            <path className="about-hero-svg hero-face translate-y-16"
                  d="M73.9,254.51c.1,1.44-.2,3.52-1.53,4.13-.34.16-.75.22-1.24.15-2.23-.34-2.76-4.46-3.76-6.08-1.88-3.08-4.74-5.5-8.18-6.64-1.11-.37-2.35-.6-3.64-.78-4.75-.67-10.21-.68-12.45-4.51-.53-.91-.88-2.02-1-3.41-.75-8.8,7.19-15.81,15.74-14.08.64.13,1.25.31,1.85.53.02,0,.03.01.05.02,10.6,3.91,13.52,21.08,14.16,30.69Z"/>
            <path className="about-hero-svg hero-face translate-y-12"
                  d="M87.13,169.7c-1.9,7.14-2.57,14.13-1.91,20.22.2,1.87.53,3.6.97,5.2-3.32,5.27-7.42,8.52-5.44,12.56,4.65,9.51-6.64,15.04-12.39,14.16-2.9-.45-5.52-1.48-7.39-2.39-.02,0-.03-.02-.05-.02-1.81-.88-2.93-1.65-2.93-1.65,0,0-8.88-4.34-9.32-12.74s10.4-10.07,13.71-13.27c.88-.85,3.43-3.21,6.74-6.21,1.03-.94,2.14-1.94,3.3-2.98,4.74-4.26,10.27-9.15,14.71-12.87Z"/>
            <path className="about-hero-svg hero-none" d="M97.38,164.48c-.55-.26-1.02-.49-1.38-.63.46.11.92.34,1.38.63Z"/>
            <path className="about-hero-svg hero-eyes translate-y-10"
                  d="M95.07,163.8c-.4.8,2.51,3.72,4.22,4.22.61.18,1.23.25,1.99.29-.72,1.05-1.23,2.43-1.36,3.98-.29,3.46,1.35,6.43,3.69,6.71-.05,0-.09.01-.14.02-2.54.34-7.65-.02-8.44,1.01-1.25,1.65-3.07,5.25-5.78,9.96-.02-.17-.05-.33-.06-.51-.77-7.15.49-15.71,3.53-24.26,1.15-.84,1.99-1.37,2.36-1.43Z"/>
            <path className="about-hero-svg hero-eyes translate-y-10 translate-x-6"
                  d="M96,163.85c.37.15.84.38,1.38.63,1.55.74,3.76,1.72,6.23,1.83,4.52.2,11.76,2.65,13.67,3.75,1.91,1.11,7.44,2.4,7.44,3.09s-7.34,4.94-12.56,5.01c-1.07.01-1.74-.04-2.28-.07-.46-.02-.84-.02-1.29.07-.99.2-.05-.8,2.77-1.11,2.81-.3,7.97-3.56,7.54-3.91s-.9-.72-.9-.72c0,0-2.45-1.65-5.56-2.81-1.48-.55-3.11-1-4.69-1.1.64,1.2.97,2.79.82,4.49-.27,3.19-2.08,5.69-4.2,5.98-.17.02-.34.03-.51.03-.04,0-.09,0-.13,0-.03,0-.06-.01-.1-.02-2.33-.27-3.98-3.24-3.69-6.71.13-1.56.64-2.93,1.36-3.98-.77-.04-1.39-.12-1.99-.29-1.71-.5-4.62-3.42-4.22-4.22.1-.21.43-.15.92.05Z"/>
            <path className="about-hero-svg hero-glasses translate-y-9"
                  d="M87.13,169.7c.61-2.31,1.36-4.64,2.23-6.96.05-.12.08-.24.13-.36.13-.34.26-.66.39-1,1.03-2.62,2.19-5.14,3.46-7.53,3.17-5.98,7.01-11.12,11.2-14.93.48-.44.96-.84,1.44-1.24,5.96-4.89,11.95-6.54,17.01-4.62,5.47,2.07,9,7.97,9.94,16.61.33,3.01.32,6.25,0,9.62-.41,4.42-1.38,9.07-2.84,13.74.32.06.64.15.95.28l11.32,5.03.57.25c2.02.9,2.99,3.2,2.31,5.26-.04.14-.07.27-.13.41-.96,2.16-3.51,3.14-5.66,2.18l-11.89-5.29c-.15-.07-.28-.16-.41-.24-3.53,8.03-8.26,14.95-13.53,19.76-4.8,4.37-9.68,6.64-14.12,6.64-1.5,0-2.95-.26-4.33-.78-4.29-1.62-7.38-5.61-8.96-11.41-.44-1.6-.77-3.33-.97-5.2-.66-6.09.02-13.08,1.91-20.22ZM89.19,189.49c.02.17.04.34.06.51.84,6.78,3.42,11.32,7.31,12.79h0c3.99,1.51,9.08-.29,14.34-5.08,5.61-5.11,10.58-12.89,14-21.92.31-.81.58-1.62.85-2.43,1.81-5.34,2.91-10.65,3.26-15.61.11-1.59.17-3.15.12-4.65-.03-1.02-.1-2.03-.2-3-.77-7.07-3.39-11.79-7.38-13.3-.92-.35-1.9-.52-2.93-.52-2.1,0-4.39.73-6.79,2.13-1.51.89-3.06,2.04-4.62,3.46-3.18,2.9-6.16,6.66-8.77,11.02-1.99,3.32-3.76,6.99-5.23,10.89-.18.48-.33.95-.5,1.43-3.04,8.55-4.3,17.12-3.53,24.26Z"/>
            <path className="about-hero-svg hero-body translate-y-48 translate-x-48"
                  d="M178.49,367.23c34.01-11.4,77.16-22.26,77.16-22.26,0,0,8.48,21.01,21.74,35.92,6.2,6.97,13.5,12.47,20.17,17.14-19.97-6.41-35.9,4-50.51,10.39-42.46,18.58-80.52,105.11-90.41,129.16-5.06,3.33-7.98,5.23-7.98,5.23h-29.86s32.52-94.23,40.48-108.83c7.96-14.6-16.89-33.65-21.05-46.02-1.68-5,17.19-12.99,40.28-20.72Z"/>
            <path className="about-hero-svg hero-ears translate-y-14 translate-x-6"
                  d="M192.65,265.06c-1.43-1.51-3.47-3.7-5.29-5.63-1.73-1.84-3.25-3.44-3.87-4-1.27-1.15,12.52-4.19,13.91-4.37.66-.09.25,3.7-.65,7.4-1,4.13-2.63,8.15-4.1,6.6Z"/>
            <path className="about-hero-svg hero-crown translate-x-28 translate-y-10"
                  d="M332.72,213.89c-7.56-11.12,16.33-38.24,3.73-46.61-1.06-.7-2.5-1.76-4.24-3.13,0-.01,0-.02,0-.04,0,0,.39,1.32,4.56-9.03,6.71-16.64,28.44-21.33,28.16-22.3,0,0,0,0,0,0,1.72.56,3.48,1.32,5.34,2.22,8.39,4.05,9.78,8.79,11.61,11.11,1.83,2.32,3.52,9.11-3.98,22.56-3.29,5.9-7.82,13.93-11.45,18.25-4.64,5.52-7.88,8.52-7.84,15.17.06,11.85,7.46,19.21,1.04,19.71-2.25.18-4.96,1.35-7.79,2.7.5-.73.97-1.46,1.37-2.19,7.05-12.9-13.11-10.53-20.53-8.44Z"/>
            <path className="about-hero-svg hero-body translate-y-48 translate-x-48"
                  d="M244.71,398.22c15.62-7.1,32.63-18.65,53.97-11.54,7.44,2.48,15.41,7.22,24.02,15.22,33.32,30.94,48.21,78.09,51.05,97.98,2.84,19.89,3.56,53.04,3.56,53.04l-231.86-4.42s.93-2.52,2.66-6.9c10.57-26.7,51.23-122.75,96.6-143.38Z"/>
            <path className="about-hero-svg hero-crown translate-x-24"
                  d="M322.31,248.54c-4.75.29-6.93-5.65-8.12-10.25-6.33-24.49-32.87-46.5-51.54-63.56-21.01-19.2-52.26-41.78-75.71-57.71-16.47-11.19-29.08-19.09-31.57-20.2-6.03-2.7-8.46-1.04-9.5-2.39-.44-.52-.8-1.38-1.1-2.49-2.5-9.23-.41-36.84,4.3-50.09.55-1.56,1.26-3.19,2.27-4.49.17-.22.36-.44.55-.64.65-.67,1.4-1.22,2.3-1.52,1.59-.54,3.31-.26,4.95.21.31.09.62.18.92.28,3.62,1.21,15.53,1.16,23.2,8.08,7.67,6.92-.92,57.12,2.81,59.73,14.84,10.42,34.77-67.09,44.05-51.51,11.11,18.66,27.07,38.35,43.34,56.12,0,0-.01,0-.01,0,0,0,0,0,.41.44,1.86,2.03,3.73,4.04,5.59,6.02,3.58,3.97,14.65,16.71,25.82,27.15,11.44,10.69,22.92,19.4,26.08,21.75.3.24.58.46.86.68,1.74,1.36,3.18,2.42,4.24,3.13,12.61,8.37-11.28,35.49-3.73,46.61,7.42-2.09,27.58-4.46,20.53,8.44-.4.74-.87,1.47-1.37,2.19-5.7,8.34-17.18,16.09-24.76,21.72-.19.14-.37.28-.56.41-1.13.81-2.33,1.55-3.66,1.79-.2.04-.4.07-.6.08Z"/>
            <path className="about-hero-svg hero-crown translate-x-28 translate-y-8"
                  d="M364.94,132.79s0,0,0,0c.28.97-21.44,5.65-28.16,22.3-4.17,10.35-4.56,9.03-4.56,9.03,0,0-.19-.14-.55-.4-.09-.07-.21-.16-.32-.24-3.16-2.35-14.64-11.05-26.08-21.75-11.17-10.44-22.25-23.18-25.82-27.15-.29-.32-.54-.59-.72-.79-3.03-3.25-4.31-4.63-4.87-5.22-.41-.44-.41-.44-.41-.44,0,0,.01,0,.01,0,.26.15,17.55,10.39,45.74,13.51,28.41,3.15,37.79-4.27,37.79-4.27,0,0,1.73.24,2.36-1.16,0,0,0-.02.01-.03.62-1.39,1.09-3.55,1.85-3.92.01,0,.02,0,.03-.01.73-.36.73-.37.73-.37,0,0,.57,1.48.9,4.02.45,3.45.71,8.42.87,10.42,0,0,0,.02,0,.03.29,3.44.78,5.49,1.2,6.44Z"/>

          </g>
        </svg>
      </div>
    </div>
  </div>
)
export default SvgComponent