import React, { ButtonHTMLAttributes, ReactNode, AnchorHTMLAttributes } from "react";
import { classNames } from "../helpers";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>
  & AnchorHTMLAttributes<HTMLAnchorElement>
  & {
  children: ReactNode;
  as?: 'a' | 'button'
};

const Button: React.FC<ButtonProps> = ({ children, className = "", as, ...props }) => {
  const baseStyles = "group bg-brand-teal text-white text-center block font-header font-medium px-4 md:px-20 py-4 text-lg sm:text-2xl rounded-md hover:bg-brand-yellow-light focus:outline-none focus:ring-2 focus:ring-brand-yellow-lighter disabled:opacity-50";
  if(as === 'a') {
    return (
      <a className={classNames(baseStyles, className)} {...props}>
        <span className="group-hover:scale-125 transition duration-500 inline-block">{children}</span>
      </a>
    );
  }
  return (
    <button className={classNames(baseStyles, className)} {...props}>
      <div className="group-hover:scale-125 transition duration-500">{children}</div>
    </button>
  );
};

export default Button;
