import React from 'react'
import {ReactComponent as FullLogo} from "../assets/images/logo-full.svg";
import {ReactComponent as LinkedInIcon} from "../assets/images/icon-linked-in.svg";
import {ReactComponent as GitIcon} from './../assets/images/icon-git.svg'
import {ReactComponent as InstaIcon} from './../assets/images/icon-insta.svg'

export default function Footer() {

  return (
    <footer className="bg-white text-brand-gray relative text-left">
      <div className="grid grid-cols-6 gap-4 footer-container relative">
        <div className="col-span-6 md:col-span-2 lg:col-span-3">
          <FullLogo className="h-24 sm:h-36" />
          <div className=" text-sm leading-6 mt-6">&copy; {new Date().getFullYear()} Cultivr LLC <br/> All rights reserved.</div>
        </div>
        <div className="col-span-6 md:col-span-4 lg:col-span-3 grid grid-cols-2 gap-y-20 font-body footer-body">
          <ul className="col-span-2 md:col-span-1 ">
            <li className="font-bold mb-3">Company</li>
            <li className=""><a className="hover:underline" href="/about">About</a></li>
            <li className=""><a className="hover:underline" href="/#process">Our Process</a></li>
            <li className=""><a className="hover:underline" href="/#contact">Join Our Team</a></li>
          </ul>
          <ul className="col-span-2 md:col-span-1">
            <li className="font-bold mb-3 ">Get in Touch</li>
            <li className="">info@cultivr.com</li>
            <li className="">631-388-4229</li>
            <li className=""><a className="hover:underline" target="_blank" rel="noreferrer" href="https://calendly.com/cultivr">Calendly</a></li>
          </ul>
          <ul className="col-span-2 sm:col-span-1 flex gap-x-4 items-end">
            <li>
              <a className="group inline" href="https://www.linkedin.com/company/cultivr" target="_blank" rel="noreferrer">
                  <LinkedInIcon className="h-10 p-2 rounded-md bg-brand-gray/10 inline group-hover:scale-90 group-hover:opacity-90 transition" />
              </a>
            </li>
            <li>
              <a className="group inline" href="https://github.com/cultivr" target="_blank" rel="noreferrer">
                <GitIcon className="h-10 p-2 rounded-md bg-brand-gray/10 inline group-hover:scale-90 group-hover:opacity-90 transition" />
              </a>
            </li>
            <li>
              <a className="group inline" href="https://www.instagram.com/cultivr" target="_blank" rel="noreferrer">
                <InstaIcon className="h-10 p-2 rounded-md bg-brand-gray/10 inline group-hover:scale-90 group-hover:opacity-90 transition" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
