import * as React from "react"
import LeftPhone from '../assets/images/home-hero-leftphone.png'
import RightPhone from '../assets/images/home-hero-rightphone.png'
import {ReactComponent as Grid} from './../assets/images/grid.svg'
import {TypeAnimation} from "react-type-animation";
import {Parallax} from "react-scroll-parallax";
import {classNames} from "../helpers";

function createFormattedArray(arr: {line: string, delay?: number}[]): (string | number)[] {
  let result: (string | number)[] = [];
  let accumulated = "";

  arr.forEach((item, index) => {
    accumulated += (index === 0 ? "" : "\n") + item.line;
    result.push(accumulated, item.delay ?? 1000);
  });

  return result;
}

const services = [
  { line: 'App Development' },
  { line: 'Website Development', delay: 2000 },
  { line: 'Full Stack Engineering' },
  { line: 'Computer Software Design', delay: 500 },
  { line: 'User Interface Design System' },
  { line: 'IT Infrastructure', delay: 1500 },
  { line: 'Computer Configuration' },
  { line: 'Project Management', delay: 10000 }
];

const sequence= createFormattedArray(services)

const SvgComponent = ({className}: { className?: string }) => (
  <div className={classNames(className || "", "relative")} style={{width: 890, height:890}}>
    <div className="absolute inset">
      <div className="transition group relative" style={{width: 890, height:890}}>
        <Grid className="absolute top-32 left-5 text-brand-gray-light/40 z-0 opacity-50" style={{width:740}} />
        <div className="absolute top-40 -left-14 z-20 z-30 animate-bob" style={{width:570, animationDelay: '3s'}}>
          <div className="relative" style={{perspective: "2000px", perspectiveOrigin: "10% 110%"}}>
            <img alt="phone showing list of services" src={LeftPhone} className="" style={{width:570}}/>
            <div style={{transform: "rotateY(15deg) rotateX(10deg) rotateZ(-15deg)", transformStyle: "preserve-3d"}}
                 className="absolute text-left left-28 bottom-20 text-brand-yellow-dark font-medium z-20 origin-top-left skew-y-6 -rotate-[17deg]">
              <div className="flex flex-col gap-y-2">
                <p className="text-brand-teal">{'<b>'}</p>
                <p className="font-medium text-5xl">What do <br /> you need?</p>
                <p className="text-brand-teal"> {'</b>'}</p>
                <p className="text-brand-teal">{'<br/>'}</p>
                <p className="text-brand-teal">{'<div>'}</p>
                <TypeAnimation
                  style={{ whiteSpace: 'pre-line', height: '195px', display: 'block' }}
                  sequence={sequence}
                  repeat={Infinity}
                />
                <br />
                <p className="text-brand-teal">{'</div>'}</p>
              </div>
            </div>
          </div>
        </div>
        <img alt="phone showing design element" src={RightPhone} className="absolute -top-0 right-20 z-30 animate-bob" style={{width:538}}/>
        <Parallax speed={-5} className="h-16 w-16 bg-brand-yellow-light/80 z-10 absolute left-[17rem] top-32 rounded-full"/>
        <Parallax speed={5} className="h-10 w-10 bg-brand-red absolute z-10 left-32 top-56 rounded-full"/>
        <Parallax speed={-15} className="h-24 w-24 bg-brand-blue-lighter absolute z-10 right-32 bottom-80 rounded-full"/>
        <Parallax speed={10} className="h-16 w-16 bg-brand-gray-light absolute right-64 bottom-20 rounded-full"/>
      </div>
    </div>
  </div>
)
export default SvgComponent