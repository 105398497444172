import React, { createContext, useContext, useState, ReactNode, useCallback } from "react";
import Alert from "./../components/Alert";

type AlertType = "success" | "error" | "warning" | "info"

interface AlertContextType {
  showAlert: (message: string, type?: AlertType) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alert, setAlert] = useState<{ message: string; type: AlertType; isVisible: boolean }>({
    message: "",
    type: "info",
    isVisible: false,
  });

  const showAlert = useCallback((message: string, type: "success" | "error" | "warning" | "info" = "info") => {
    setAlert({ message, type, isVisible: true });

    setTimeout(() => {
      setAlert((prev) => ({ ...prev, isVisible: false }));
    }, 10000);
  }, []);

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <Alert message={alert.message} type={alert.type} isVisible={alert.isVisible} onClose={() => setAlert((prev) => ({ ...prev, isVisible: false }))} />
    </AlertContext.Provider>
  );
};
