import React, { InputHTMLAttributes, TextareaHTMLAttributes} from "react";
import {classNames} from "../helpers";

type InputProps = InputHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement> &{
  label?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string
};

const Input = React.forwardRef<HTMLInputElement & HTMLTextAreaElement, InputProps>(({
                                       label,
                                       type = "text",
                                       placeholder = "",
                                       disabled = false,
                                       error,
                                       ...props
                                     }, ref) => {
  const baseStyles = "col-span-2 peer text-left border-b-2 border-gray-300 text-lg sm:text-xl lg:text-2xl focus:outline-none focus:ring-0 transition-all";
  const stateStyles = classNames(
    "border-gray-300 focus:border-b-blue-500",
    error ? "border-red-500 focus:border-b-red-500" : "",
    disabled ? "opacity-50 cursor-not-allowed" : ""
  );

  const labelStyles = classNames(
    "peer-focus:scale-75 origin-left transition order-first text-left text-xl sm:text-2xl lg:text-3xl ",
    error ? "text-red-500" : "peer-focus:text-blue-500 text-gray-500",
  )

  return (
    <div className="flex flex-col col-span-2 mt-2 mb-6">
      {type === 'textarea' ?
        <textarea
          ref={ref}
          placeholder={placeholder}
          disabled={disabled}
          rows={8}
          className={classNames(baseStyles, stateStyles)}
          {...props}/>:
      <input
        ref={ref}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        className={classNames(baseStyles, stateStyles)}
        {...props}
      />}
      {label && <label className={labelStyles}>{label}</label>}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
});

export default Input;
