import React, { useState, useEffect, createContext, useContext, ReactNode, ButtonHTMLAttributes, AnchorHTMLAttributes, HTMLAttributes } from 'react';
import Button from "../components/Button";

interface NavigationContextProps {
  currentPage: string;
  currentHash: string;
  setCurrentPage: (page: string) => void;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

type LinkProps = ButtonHTMLAttributes<HTMLButtonElement>
  & AnchorHTMLAttributes<HTMLAnchorElement>
  & HTMLAttributes<HTMLElement>
  & {
  as: 'button' | 'a' | 'div',
  to: string,
  children: ReactNode;
};

export const Link: React.FC<LinkProps> = ({ as = 'div', to, children, href, onClick, ...props }) => {
  const { setCurrentPage } = useNavigation();
  return (
    <>
      {as === 'button' && <Button onClick={onClick || (() => setCurrentPage(to))} {...props}>{children}</Button>}
      {as === 'div' && <div onClick={onClick || (() => setCurrentPage(to))} {...props}>{children}</div>}
      {as === 'a' && <a href={href || `#${to}`} onClick={href ? undefined : (onClick || (() => setCurrentPage(to)))} {...props}>{children}</a>}
    </>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error("useNavigation must be used within a NavigationProvider");
  }
  return context;
};

interface NavigationProviderProps {
  children: ReactNode;
}

export function NavigationProvider({ children }: NavigationProviderProps) {
  const [currentPage, setCurrentPage] = useState<string>(() => {
    const hash = window.location.hash.replace("#", "");
    const path = window.location.pathname.replace("/", "") || "";
    return hash ? `${path}#${hash}` : path;
  });

  useEffect(() => {
    const [page, section] = currentPage.split("#");
    const url = new URL(window.location.href);
    url.pathname = `/${page}`;
    url.hash = section ? `#${section}` : "";
    window.history.pushState(null, "", url.toString());
  }, [currentPage]);

  useEffect(() => {
    const handleNavigationChange = () => {
      const hash = window.location.hash.replace("#", "");
      const path = window.location.pathname.replace("/", "") || "";
      setCurrentPage(hash ? `${path}#${hash}` : path);
    };

    window.addEventListener("hashchange", handleNavigationChange);
    window.addEventListener("popstate", handleNavigationChange);

    return () => {
      window.removeEventListener("hashchange", handleNavigationChange);
      window.removeEventListener("popstate", handleNavigationChange);
    };
  }, []);

  return (
    <NavigationContext.Provider value={{ currentPage, setCurrentPage, currentHash: currentPage.split("#")[1] || "" }}>
      {children}
    </NavigationContext.Provider>
  );
}
