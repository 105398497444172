import {useCallback, useState} from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {ReactComponent as CultivrLogo} from './../assets/images/logo.svg'
import {ReactComponent as CultivrLogoAlt} from './../assets/images/logo-alt.svg'
import {ReactComponent as MenuIcon} from './../assets/images/icon-menu.svg'
import {classNames} from "../helpers";


const navigation = [
  { name: 'Home', href: '/#home', featured: false },
  { name: 'Services', href: '/#services', featured: false },
  { name: 'Our Process', href: '/#process', featured: false },
  { name: 'About Us', href: '/about', featured: false },
  { name: 'Get Started', href: '/#contact_us', featured: true },
]

export default function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const onDialogNavigate = (path: string) => {
    onOpenMobileMenu(false)
    window.location.href = path
  }

  const onOpenMobileMenu = useCallback((open: boolean) => {
    document.documentElement.style.scrollBehavior =  open ? 'auto' : "scroll";
    setMobileMenuOpen(open)
  }, [setMobileMenuOpen])

  return (
    <header className="bg-white/90 border-b border-brand-gray-lighter fixed w-full z-40">
      <nav className="mx-auto flex max-w-10xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="-m-1.5 p-1.5">
          <span className="sr-only">Cultivr</span>
          <CultivrLogo className="h-12 w-auto" onClick={() => window.location.href = '/'} />
          {/*<img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />*/}
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => onOpenMobileMenu(true)}
          >
            <span className="sr-only">Open main menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map(({ href, featured,name}) => (
              <a key={name} href={href} className={classNames("text-lg leading-6", featured ? "text-brand-teal font-semibold hover:scale-125 transition" : "text-gray-900 font-medium hover:underline")}>
              {name}
            </a>
          ))}
          {/*<a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>*/}
        </div>
      </nav>
      {<Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={onOpenMobileMenu}>
        <div className={classNames("fixed inset-0 z-30 transition", mobileMenuOpen ? "bg-white/50": "bg-white/50")} />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-60 overflow-y-auto bg-brand-yellow-light text-white shadow-lg px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-end">
            <button
              type="button"
              className="-m-2.5 mt-1 rounded-md text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-10 w-10 text-brand-blue-light" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <span
                    key={item.name}
                    onClick={() => onDialogNavigate(item.href)}
                    className="-mx-3 border-b border-white/50 block text-right ml-10  mr-1 py-2 text-base font-semibold leading-7 text-white hover:bg-white/50"
                  >
                    {item.name}
                  </span>
                ))}
              </div>
              <CultivrLogoAlt className="mt-10 h-8 w-auto ml-auto" onClick={() => window.location.href = '/'} />

            </div>
          </div>
        </Dialog.Panel>
      </Dialog>}
    </header>
  )
}
