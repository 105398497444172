import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation} from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import {classNames} from "../helpers";

import { PlusIcon } from '@heroicons/react/24/outline'
import {ReactComponent as NikeLogo} from './../assets/images/company-Nike.svg'
import {ReactComponent as Safeway} from './../assets/images/company-Safeway.svg'
import {ParallaxBanner} from "react-scroll-parallax";

import {ReactComponent as Rectangle1} from './../assets/images/Rectangle1.svg'
import {ReactComponent as Rectangle2} from './../assets/images/Rectangle2.svg'
import {ReactComponent as Rectangle3} from './../assets/images/Rectangle3.svg'
import {ReactComponent as PhoneIcon} from './../assets/images/phone-icon.svg'
import {ReactComponent as ArrowIcon} from './../assets/images/arrow-icon.svg'
import {ReactComponent as UiIcon} from './../assets/images/ui-icon.svg'

import {ReactComponent as EmailIcon} from './../assets/images/icon-email.svg'
import {ReactComponent as LinkedInIcon} from './../assets/images/icon-linked-in.svg'

import {ReactComponent as NeedsIcon} from './../assets/images/icon-needs.svg'
import {ReactComponent as SolutionIcon} from './../assets/images/icon-solutions.svg'
import {ReactComponent as PartnershipIcon} from './../assets/images/icon-partnership.svg'
import {ReactComponent as WorkIcon} from './../assets/images/icon-work.svg'

import OutlineImage from './../assets/images/outline-bg.svg'
import GridBG from './../assets/images/grid-centered-white.svg'
import GridTalent from './../assets/images/grid-talent.svg'
import ContactImage from './../assets/images/contact-bg.png'
import TeamManny from './../assets/images/team-manny-2.jpeg'

import ServicesWebDev from './../assets/images/services_web_development.png'
import ServicesAppDev from './../assets/images/services_app_development.png'
import ServicesUiUx from './../assets/images/services_ui_ux.png'
import ServicesIT from './../assets/images/services_it.png'
import ServicesPM from './../assets/images/services_project_management.png'
import ServicesStaffing from './../assets/images/services_staffing.png'
import Input from "../components/Input";
import React from "react";
import Button from "../components/Button";
import HomeHero from "../components/HomeHero";
import {Parallax} from "react-scroll-parallax";
import {SubmitHandler, useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {useAlert} from "../hooks/useAlert";

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().required(),
  })
  .required()

const services = [
  {
    title: 'Web Development',
    sm_title: 'Web Development',
    description: "PHP, Swift, Javascript, SQL",
    image: ServicesWebDev,
    details: [ 'Web Development',
    'Software Engineering', 'SaaS Development',
    'Design, Wireframing, Prototyping + Build',
    'Responsive and Adaptive Websites',
    'Front-End & Back-End Development',
    'Website Migration + Maintenance',
    'Dev Ops', 'Databases']
  },
  {
    title: 'App Development',
    sm_title: 'App Development',
    description: "iOS, Android",
    image: ServicesAppDev,
    details: [ 'Research','Userflows','UX Design',' Prototyping',' Testing',' Build + Maintenance',' API Integration']
  },
  {
    title: 'User Interface/Experience Design ',
    sm_title: 'UI/UX Design ',
    description: "Figma, Sketch, XD, Invision",
    image: ServicesUiUx,
    details: [ 'UX Research',' Writing',' User Flows + Journeys',' UI Design Systems (Icons, etc)',' Mockups + Wireframes',' Rapid Prototyping ']
  },
  {
    title: 'IT Solutions',
    sm_title: 'IT Solutions',
    description: "G-Suite, Microsoft, Remote Offices",
    image: ServicesIT,
    details: ['IT + Cloud Infrastructure', 'Computer Configuration', 'Printer', 'Fax and Landlines', 'Internet Routers', 'Bluetooth + Wifi', 'Remote AccessControl', 'Troubleshooting'],
  },
  {
    title: 'Staffing Solutions',
    sm_title: 'Staffing Solutions',
    description: "Short Term, Long Term, Team Augmentation",
    image: ServicesStaffing,
    details: [ 'Team Augmentation','Architects',' Software Engineers',' Designers',' Full Stack Developers',' Contractors/Contracting',' Project Management']
  },
  {
    title: 'Project Management',
    sm_title: 'Project Management',
    description: "Agile, Scrum, Kanban",
    image: ServicesPM,
    details: [ 'Jira', 'Trello','Asana','Basecamp','Agile & Scrum Methodologies','Statement of Work (SOWs)','Standard Operating Procedures (SOPs)', 'Workflows, etc.']
  }
]

const process = [
  {
    step: 1,
    title: 'Tell Us Your Needs',
    description: 'Whether you seek guidance for your next project or know exactly what you need, contact us for an introduction.',
    list: ['Free Consultation', 'Response within 24 hours', 'Communication options available'],
    className: 'bg-brand-yellow-light text-brand-red',
    icon: <NeedsIcon className="w-24" />,

  },
  {
    step: 2,
    title: 'We Provide You With Solutions',
    description: 'Once we understand your goals and expectations, we move swiftly in tailoring the resolution.',
    list: ['Access to quality Engineers, Architects, and IT Professionals', 'Budget, timeline and pricing options included'],
    className: 'bg-brand-red text-brand-yellow',
    icon: <SolutionIcon className="w-24" />
  },
  {
    step: 3,
    title: 'We Get to Work',
    description: 'Whether in-Person, remote or hybrid– we work with you every step of the way, ensuring your projects delivery.',
    list: ['Private Slack Channel for quick responses', 'Project Management tools to keep track of progress'],
    className: 'bg-brand-blue-lighter text-brand-blue-darker',
    icon: <WorkIcon className="w-24" />
  },
  {
    step: 4,
    title: 'Our Partnership Begins',
    description: 'As new technology emerges, so should we. It is our obligation to keep in touch within the weeks, months or years ahead.',
    list: ['Long-Term Staffing', 'Application Maintenance'],
    className: 'bg-brand-blue-darker text-brand-blue-light',
    icon: <PartnershipIcon className="w-24" />
  }
]

const calendlyLink = "https://calendly.com/cultivr"

interface ImpactTileProps {
  Shape: any
  shapeClassName: string
  Icon: any
  title: string
  description: string
}

type ContactFormInputs = {
  name: string
  email: string
  message: string
}

function ImpactTile({Shape, shapeClassName, Icon, title, description}: ImpactTileProps) {
  return (<div className="col-span-1 relative z-1 group min-h-100 sm:max-w-100">
    <div className="absolute z-1 inset-0 bg-brand-blue-medium rounded-md"/>
    <Shape className={classNames("group-hover:scale-125 duration-500 origin-top-left transition absolute w-112 z-1 text-brand-blue opacity-70", shapeClassName)} />
    <div className="text-white w-full h-full relative p-10 flex flex-col">
      <div className="my-8"><Icon className="h-14 sm:h-16 md:h-24 w-auto" /></div>
      <h4 className="col-span-6 text-left text-2xl font-bold">{title}</h4>
      <p className="col-span-3 text-left font-body text-xl md:text-xl font-medium">{description}</p>
    </div>
  </div>)
}

function ServiceDetails({details, title}: { details: string[], title: string }) {
  return <>
    {details.map((detail, index) => <p key={`detail-${title}-${index}`} className="italic font-light text-base sm:text-lg text-brand-gray-dark">{detail}</p>)}
  </>
}

export default function Home() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactFormInputs>({
    resolver: yupResolver(schema),
  })
  const { showAlert } = useAlert();
  const onSubmit: SubmitHandler<ContactFormInputs> = async (data) => {
    const response = await fetch('https://lively-truth-4b56.jide-158.workers.dev/cultivr', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        message: data.message
      }),
    })
    if (response.ok) {
      reset()
      showAlert("Thanks! We will be in touch soon!", "success");
    } else {
      showAlert("There was an error. Please try again in a few seconds.", "error");
    }
  }

  return (
    <>
      <section className="bg-white pt-20 overflow-hidden" id="home">
        <div className="section-container grid grid-cols-1 xl:grid-cols-2 xl:grid-rows-2 ">
          <div className="flex justify-start items-end">
            <h1 className="text-left section-heading text-brand-gray-dark pr-4">Programming and <br className="hidden"/> Developer Solutions, <br className="hidden"/> <span className="text-brand-teal">Today</span></h1>
          </div>
          <div className="order-last xl:col-start-1 xl:row-start-2 flex flex-col justify-start items-center sm:items-start">
            <p className="text-left my-4 sm:my-8 md:my-12 section-body">Cultivating <span className="hidden sm:inline">your</span> ideas. Transforming <span className="hidden sm:inline">our</span> reality.</p>
            <Button as="a" href="/#contact_us" className="w-full xl:w-96" >Get Started</Button>
            <div className="flex items-center gap-x-5 mt-4 sm:mt-10 md:mt-20">
              <div className="text-left section-body">Trusted By</div>
              <NikeLogo className="w-20 sm:w-24 text-brand-gray-light" />
              <Safeway className="w-32 sm:w-40 text-brand-gray-light"/>
            </div>
          </div>
          <div className="xl:row-span-2 xl:col-start-2 xl:row-start-1 relative">
            <div className="w-full block">
              <div className="w-full h-100 sm:h-160 2xl:w-224 2xl:h-224 relative">
                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <HomeHero className="scale-40 sm:scale-60 md:scale-75 2xl:scale-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ParallaxBanner layers={[{ image: OutlineImage, speed: -15 }]} className="bg-cover bg-center bg-brand-blue-darker pt-16 sm:pt-24 pb-0 pb-10 xl:pb-24 2xl:pb-0">
        <div className="section-container grid grid-cols-3 lg:grid-cols-6 text-white z-10 relative">
          <h1 className="col-span-6 text-left section-heading mb-6 md:mb-12">Concentrated Team. Large Impact.</h1>
          <p className="col-span-3 text-left mb-12 section-body">We guarantee the success of your mission through our experienced team of devoted innovators.</p>
          <div className="col-span-6">
            <div className="grid grid-cols-1 grid-rows-3 sm:grid-rows-2 sm:grid-cols-2 lg:grid-rows-1 lg:grid-cols-3 gap-8">
              <ImpactTile Shape={Rectangle3} shapeClassName="-top-2 sm:-top-8 lg:-top-4 -left-10 sm:-left-36 md:-left-20 lg:-left-32" Icon={PhoneIcon} title="Free Consultation" description="No task is too large or small for us to tackle. Reach out to us for a brief introduction." />
              <ImpactTile Shape={Rectangle1} shapeClassName="top-2 sm:top-8 -left-8 lg:-left-16" Icon={ArrowIcon} title="Flexible Options" description="In-Person or remote? Hourly or fixed? Long-term or short? You set the expectations, we will work around it." />
              <ImpactTile Shape={Rectangle2} shapeClassName="-top-4 md:-top-16 -left-10 md:left-0" Icon={UiIcon} title="100% Custom-Made" description="We make your product unique by offering full range customizations and personalization." />
            </div>
          </div>

          <div className="col-span-6 text-left mt-16">
            <Button as="a" href="/#process" className="border border-brand-teal bg-transparent text-white w-full xl:w-96">View Our Process</Button>
          </div>
        </div>
      </ParallaxBanner>
      <section className="bg-white pt-2 sm:pt-24" id="services">
        <div className="mx-auto section-container">
          <h1 className="col-span-6 text-left mb-12 section-heading text-brand-gray-dark">Services and Skillsets</h1>
          <div className="mx-auto grid grid-cols-6 gap-2 lg:gap-4 relative">
          {services.map(({ title, sm_title, image, description, details}) =>
            <div key={`service-${title}`} className="col-span-6 sm:col-span-3 lg:col-span-2 bg-center bg-cover bg-brand-blue-lightest rounded-lg px-4 py-16 sm:px-14 sm:py-20 md:py-16 relative group hover:bg-brand-yellow-lightest transition-colors ease-in-out duration-300" style={{backgroundImage: `url(${GridBG})`}}>
              <PlusIcon className="absolute right-0 top-0 h-6 m-2 md:m-9 text-brand-teal group-hover:text-brand-yellow transition-colors ease-in-out duration-300"/>
              <div className="absolute inset-0 flex flex-col justify-center p-4 items-center opacity-0 group-hover:opacity-100 transition ease-in-out duration-300 transition -translate-y-5 group-hover:translate-y-0">
                <h4 className="col-span-6 text-xl font-medium text-brand-gray-dark">{title}</h4>
                <ServiceDetails details={details} title={title} />
              </div>
              <div className="opacity-100 group-hover:opacity-0 transition-opacity ease-in-out duration-300">
                <img className="mx-auto w-48 transition-transform ease-in-out duration-300 group-hover:translate-y-5" src={image} alt={title} />
                <h4 className="hidden sm:block col-span-6 text-xl font-medium text-brand-gray-dark">{title}</h4>
                <h4 className="block sm:hidden col-span-6 text-xl font-medium text-brand-gray-dark">{sm_title}</h4>
                <p className="italic font-light text-base sm:text-lg text-brand-gray-dark">{description}</p>
              </div>

            </div>
          )}
          </div>
        </div>
      </section>
      <section className="bg-gray-50 py-2 sm:py-24 overflow-hidden" id="process">
        <div className="grid grid-cols-7 section-container">
          <h1 className="col-span-7 section-heading text-left text-gray-800">Our Process</h1>
          <p className="hidden sm:block col-span-7 xl:col-span-5 text-left section-body my-12">We use human-centered design principles to produce innovative experiences. From start to finish, our simple and straightforward process will get who you need, when you need it.</p>
          <div className="sm:hidden col-span-7 mt-10 gap-y-6 grid grid-cols-1 grid-rows-4">
            {process.map(({ className,step, title, description, list }, index) =>
              <div className={classNames(className, "grid grid-cols-1 row-span-1 col-span-1 p-6 text-left rounded-lg w-full")} key={`process-slide-${index}`}>
                <div className="col-span-1">
                  <h5 className="text-5xl font-header-2 mb-4">{step}</h5>
                  <h2 className="text-xl font-header font-medium mb-2 text-white">{title}</h2>
                  <ul className="text-lg font-body text-white list-disc ml-4">
                    {list.map((item, listIndex) => <li  key={`process-${title}-${listIndex}`}>{item}</li>)}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mx-auto px-10 w-full max-w-8xl">
          <div className="hidden sm:block w-full max-w-2xl overflow-visible">
          <Swiper
            modules={[Navigation]}
            navigation={true} pagination={false}
            spaceBetween={5}
            loop={true}
            loopFillGroupWithBlank={true}
            initialSlide={4}
            loopedSlides={3}
            slidesPerView={1}
            className="process-slider flex"
          >
            {[...process, ...process].map(({ className,step, title, description, list, icon }, index) =>
                <SwiperSlide className="h-auto" key={`process-slide-${index}`}>
              <div className={classNames(className, "grid grid-cols-5 p-14 text-left h-full rounded-md")}>
                <div className="col-span-4">
                  <h5 className="text-8xl font-header-2 mb-6">{step}</h5>
                  <h2 className="text-2xl md:text-3xl  font-header font-medium mb-6 text-white">{title}</h2>
                  <p className="text-xl md:text-2xl font-body mb-6 text-white">{description}</p>
                  <ul className="text-xl md:text-2xl font-body text-white list-disc ml-5 h-32">
                    {list.map((item, listIndex) => <li  key={`process-${title}-${listIndex}`}>{item}</li>)}
                  </ul>
                </div>
                <div className="col-span-1 flex items-end justify-end">
                  {icon}
                </div>
              </div>
            </SwiperSlide>
            )}
          </Swiper>
          </div>
          <Button as="a" href="/#contact_us" className="w-full xl:w-96 mx-auto sm:mt-16 mb-10">Let's Get Started</Button>
        </div>

      </section>
      <section className="bg-white overflow-hidden pt-2 sm:pt-24" id="contact_us">
        <div className="grid grid-cols-6 gap-4 section-container">
          <div className="col-span-6 lg:col-span-3 pr-6 xl:pr-24 relative">
            <h1 className="text-left mb-6 sm:mb-12 text-brand-gray-dark section-heading"><span className="hidden xl:inline">Hello, </span>It's A Pleasure to Meet You!</h1>
            <p className="col-span-5 text-left my-6 sm:my-12 section-body">
              In as many words as possible tell us about your needs, your timeframe, your budget and any other details that we should know.  We will reach out to you within the next few days to further discuss.
            </p>
            <p className="col-span-5 text-left my-6 sm:my-12 section-body">
              <b>Ready to speak now?</b> Skip the mailing form and schedule your consultation.  We look froward to hearing from you!
            </p>
            <div className="hidden lg:block w-full max h-96 bottom-0 bg-right-top right-0 absolute inset overflow-visible opacity-80" style={{backgroundImage: `url(${ContactImage})`, width: 1200}}/>
          </div>
          <div className="col-span-6 lg:col-span-3">
            <form className="lg:py-44 grid grid-cols-2 text-left" onSubmit={handleSubmit(onSubmit)}>
              <Input {...register("name")} error={errors.name?.message} type="text" label="Full Name" />
              <Input {...register("email")} error={errors.email?.message} type="text" label="Email" />
              <Input {...register("message")} error={errors.message?.message} type="textarea" label="Message" />
              <div className="col-span-2 pb-10"/>
              <Button className="col-span-2 2xl:col-span-1">Send a Message</Button>
              <a href={calendlyLink} target="_blank" rel="noreferrer"  className="text-center underline hover:text-brand-yellow hover:scale-105 transition col-span-2 2xl:col-span-1 text-brand-teal font-header py-4 text-lg sm:text-2xl rounded-md">Schedule a Consultation</a>
            </form>
          </div>
          <div className="col-span-6 lg:hidden w-full bg-cover h-80 sm:h-100 opacity-80 relative overflow-visible">
            <div className="absolute -right-36 md:-right-24 top-0 w-280 h-112 bg-cover" style={{backgroundImage: `url(${ContactImage})`}}/>
          </div>
        </div>
      </section>
      <section className="bg-brand-blue-darker text-white relative overflow-hidden py-2 sm:py-24">
        <Parallax speed={-5} className="h-80 w-80 bg-brand-blue-light absolute right-10 bottom-14 rounded-full z-10"/>
        <div className="grid grid-cols-6 gap-4 section-container relative">
          <div className="absolute top-0 bottom-0 right-10 h-full w-full max-w-5xl opacity-50 bg-cover" style={{backgroundImage: `url(${GridTalent})`}} />
          <h1 className="col-span-6 section-heading text-left mb-4 md:mb-12 leading-tight z-20">Talent Spotlight</h1>
          <div className="col-span-6 lg:col-span-3 lg:pr-10 xl:pr-36 relative">
            <div className="relative w-full max-w-3xl h-80 sm:h-96 md:h-112">
              <Parallax speed={-15} className="h-36 w-36 bg-brand-yellow-light absolute -right-20 -top-6 rounded-full z-10"/>
              <div className="absolute bg-brand-blue-medium w-full h-full inset-0 bg-cover bg-top z-20"  style={{backgroundImage: `url(${TeamManny})`}}/>
              <Parallax speed={10} className="h-14 w-14 bg-brand-red absolute -left-7 bottom-14 rounded-full"/>
            </div>
          </div>
          <div className="col-span-6 lg:col-span-3 z-10 flex flex-col gap-y-6 items-start text-left font-body text-xl sm:text-2xl xl:text-2xl 2xl:text-3xl font-medium justify-between">
            <p className="pr-2">
              Providing over ten years of exquisite customer service experience with companies Carvana, Lexus, and Rockstar Games, our IT Specialist <b>Emmanuel Lewis</b> adores civic systems. His passion for streamlining, decluttering and organization have consistently helped increase productivity for internal teams.
            </p>
            <p>
              Say hello!
            </p>
            <div className="bg-brand-blue-darkest inline-block rounded-md py-5 px-8">
              <a className="group inline" href="mailto:info@cultivr.com" >
                <EmailIcon className="h-8 md:h-16 mt-1 md:mt-3 mx-4 inline group-hover:scale-95 group-hover:opacity-90 transition" />
              </a>
              <a className="group inline" href="https://www.linkedin.com/in/emmanuel-m-lewis-9b9133122/" target="_blank" rel="noreferrer">
                <LinkedInIcon className="h-8 md:h-16 mb-1 md:mb-3 mx-4 inline group-hover:scale-90 group-hover:opacity-90 transition" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
