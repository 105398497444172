import HeroImage from './../components/Hero'
import GridTalent from './../assets/images/grid-talent.svg'
import {ReactComponent as SunshineLogo} from './../assets/images/sunshine_logo.svg'
import {ReactComponent as EigenX} from './../assets/images/sunshine_logo.svg'
import {ReactComponent as Buildee} from './../assets/images/sunshine_logo.svg'
import {ReactComponent as Velocity} from './../assets/images/sunshine_logo.svg'
import React from "react";
import {Parallax} from "react-scroll-parallax";
import JideImage from './../assets/images/team-jide.jpg'
import MannyImage from './../assets/images/team-manny.jpg'
export default function About() {
  const aboutPillars = [
    { heading: 'Building Confidently', body: 'From our initial conversation, we proactively aim to address your challenge with industry-leading best practices that identify and mitigate risk. Through UX research and  discovery phases, we inform you of our findings and strategic approach to its execution so that you know we’re building the right thing, at the right time.'},
    { heading: 'Production and Delivery', body: 'Through the development process, we create user journeys and flows that transition into wireframes and rapid prototyping for your approval.  We also conducts series of stress tests with actual users to ensure quality consistency, regardless of the hardware /device. '},
    { heading: 'Responding to Change', body: 'Our expert staff thinks holistically about what we build to ensure that as technology advances, so does your product.  Whether you need to replace legacy systems or deliver all new digital services, Cultivr helps agencies become more adaptable and resilient.'},
  ]

  const workSamples = [
    {title: "Sunshine Environmental", logo: () => <SunshineLogo />, image: "", description: "",  keywords: ""},
    {title: "Buildee", logo: () => <Buildee />, image: "", description: "", keywords: "" },
    {title: "Velocity", logo: () => <Velocity />, image: "", description: "", keywords: "" },
    {title: "EigenX", logo: () => <EigenX />, image: "", description: "", keywords: "" },
  ]

  const leadership = [
    {image: JideImage, name: 'Jide Osanyingbemi', title: 'Principal Engineer', location: 'Philadelphia, Pennsylvania', bio: "Jide is a creative problem solver with 16+ years of experience as a full-stack engineer and technical leader. Jide has implemented modular architectures that has helped teams build faster and more deploy more reliable products. His expertise lies in designing AI-powered tools, scalable platforms, and innovative digital solutions."},
    {image: MannyImage, name: 'Emmanuel Lewis', title: 'Sales & IT Specialist', location: 'Long Island, New York', bio: "With over a decade of experience in sales, IT, and customer service at top companies like Carvana, Lexus, and RockStar Games, Manny brings a unique blend of technical expertise and business acumen. Passionate about streamlining systems and enhancing productivity, he leverages his IT skills to drive efficiency and innovation."}
  ]

  return (
    <>
      <section className="bg-white overflow-hidden">
        <div className="mx-auto grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3 max-w-10xl px-6 sm:px-16 xl:px-20 m-auto">
          <div className="col-span-1 lg:row-span-1 lg:col-start-1 lg:row-start-1 flex flex-col justify-end items-start pt-28 sm:pb-14">
            <h1 className="text-left text-4xl sm:text-6xl xl:text-7xl leading-tight text-brand-gray-dark">Cultivating ideas and
              Transforming realities</h1>
          </div>
          <div className="col-span-1 gap-y-10 lg:row-span-2 lg:col-start-1 lg:row-start-2 flex flex-col justify-start items-start order-last lg:order-none pt-0 sm:pt-10 lg:pt-0 pb-10">
            <p className="text-left font-body text-xl sm:text-xl lg:text-2xl font-medium lg:leading-loose">
              Great technologies have the power to inspire, transform and shift the way the world works. Founded by Jide Osanyingbemi in 2018, Cultivr’s mission is to reduce the worlds noise by adding value, reinstating purpose and empowering individuals through innovative experiences.
            </p>
            <p className="text-left font-body text-xl sm:text-xl lg:text-2xl font-medium lg:leading-loose">
              Consisting of skilled designers, developers and architects, Cultivr offers a range of flexible services that aid in the conceptualization of a product and the improvement of existing ones, supporting change at every level through transparent production practices.
            </p>
          </div>
          <div className="col-span-1 lg:row-span-3 lg:col-start-2 flex items-center justify-start relative">

            <div className="w-full h-96 sm:h-148 md:h-160 2xl:w-240 2xl:h-240 relative flex items-center justify-start">
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2  -translate-y-1/2 lg:left-0 lg:translate-x-0">
                <HeroImage className="scale-40 sm:scale-60 md:scale-75 lg:origin-left lg:scale-100" />
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="bg-brand-gray-lightest lg:py-16">
        <div className="grid grid-cols-4 lg:grid-cols-12 gap-x-10 2xl:gap-x-20 items-start section-container">
          {aboutPillars.map(({heading, body}, index) => (
            <div key={`about-pillar-${index}`} className="col-span-4 flex flex-col justify-center items-start">
              <div className="flex flex-col items-start justify-start my-6">
                <h1 className="text-left text-4xl leading-tight text-brand-teal h-24">{heading}</h1>
                <p className="text-left font-body text-2xl font-medium">{body}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="bg-white lg:py-24">
        <div className="section-container">
          <h1 className="w-full text-left sm:mb-12 text-4xl sm:text-6xl xl:text-7xl leading-tight">Meet Our Leadership</h1>
          <div className="w-full flex flex-col lg:flex-row justify-start">
          {leadership.map(({name, title, location, image, bio}, index) => (
            <div key={`about-pillar-${index}`} className="w-full lg:w-1/2 2xl:w-1/3 flex flex-col justify-center items-center">
              <div className="h-88 w-88 sm:h-96 sm:w-96 bg-brand-gray-lightest rounded-full m-10 bg-cover bg-center flex" style={{backgroundImage: `url(${image})`}}>
                <div className="w-full h-full text-lg rounded-full bg-brand-yellow-lightest hover:opacity-90 opacity-0 transition-opacity py-10 sm:py-20 px-14 leading-tight">{bio}</div>
              </div>
              <div className="w-full text-center font-body">
                  <h1 className="text-2xl font-bold leading-tight text-brand-gray-dark/60 mb-2">{name}</h1>
                  <div className="text-xl text-brand-gray-dark/50 italic font-medium">{title}</div>
                  <div className="text-xl text-brand-gray-dark/50 italic font-medium">{location}</div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </section>

      <section className="bg-brand-gray-lighter py-24 hidden">
        <div className="mx-auto grid grid-cols-12 gap-10 max-w-10xl p-6 lg:px-16">
          {workSamples.map(({title, logo}, index) => (
            <div key={`work-samples-${index}`} className="col-span-3 justify-center items-start bg-white p-10 rounded-lg">
              {logo()}
            </div>
          ))}
        </div>
      </section>

      <section className="bg-brand-blue-darker text-white relative overflow-hidden lg:py-24">
        <div className="grid grid-cols-6 gap-4 section-container relative">
          <Parallax speed={5} className="absolute top-0 bottom-0 right-0 h-full w-full max-w-5xl opacity-50 bg-cover" style={{backgroundImage: `url(${GridTalent})`}} />
          <div className="col-span-6 lg:col-span-4 xl:col-span-3 relative z-20">
            <h1 className="col-span-6 text-left mb-12 sm:mb-12 text-4xl sm:text-6xl xl:text-7xl leading-tight">Message From <br className="hidden lg:inline" /> Our Partners</h1>
            <p className="col-span-5 text-left font-body pr-10 xl:pr-32 text-2xl font-medium">
              I’m beyond thrilled with the website Jide and his team built—it gave my company a polished, professional look! His communication was fast, thorough, and truly exceptional. His team genuinely care about their work and are passionate about delivering real value. I highly recommend them to anyone looking for a dedicated and talented development team!
              <br /> <br />
              <span className="text-brand-teal italic text-xl sm:text-2xl">- Joyce O, <b>Chief Operating Officer</b> <br/> <span className="font-bold text-base not-italic">Sunshine Environmental Solutions</span></span>
            </p>
          </div>
          <div className="col-span-6 lg:col-span-2 xl:col-span-3 absolute lg:relative z-10 left-24 sm:left-52">
            <Parallax speed={-10}>
              {/*<EigenXLogo className="absolute my-auto text-brand-blue-medium left-0 top-10" style={{width: 1100}} /> */}
              <SunshineLogo className="absolute my-auto text-brand-blue-medium left-0 top-10 w-100 lg:w-100 xl:w-212" />
            </Parallax>

          </div>
        </div>
      </section>
    </>
  )
}
