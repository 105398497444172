import React, {useEffect} from 'react';
import './App.css';
import Nav from "./components/Nav";
import Home from "./pages/Home";
import About from "./pages/About"
import {useNavigation} from "./hooks/useNavigation";
import Footer from "./components/Footer";


function App() {
  const { currentPage, currentHash } = useNavigation();

  useEffect(() => {
    if (currentHash) {
      setTimeout(() => {
        document.getElementById(currentHash)?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [currentHash]);

  const renderPage = () => {
    switch (currentPage) {
      case "about":
        return <About />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="App">
      <Nav />
      {renderPage()}
      <Footer />
    </div>
  );
}

export default App;
